import React from "react";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { WithSearch, SearchProvider, Results, SearchBox, Sorting, Paging, PagingInfo } from "@elastic/react-search-ui";
import { Layout as SearchLayout } from "@elastic/react-search-ui-views";
import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import generateFrontendPath from "../util/generateFrontendPath.js"
import removeTags from "../util/removeTags.js"
import { graphql, Link } from "gatsby";
import unescape from 'underscore/modules/unescape.js'
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "../styles/search.scss";

const useStyles = makeStyles((theme) => ({
  filters: {
    backgroundColor: grey["300"],
    overflow: "hidden",
    padding: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
    margin: 0,
  },
  button: {
    height: "100%",
    width: "100%",
  },
  chip: {
    marginBottom: 3,
    marginTop: 3,
    marginRight: 6,
  },
  list: {
    marginBottom: theme.spacing(4),
    "& li": {
      borderBottom: `${grey["300"]} 1px solid`,
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  row: {
    alignItems: "center",
    "& > div ": {
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
    },
  },
  chipLabel: {
    display: "inline-block",
    paddingRight: 10,
  },
  searchResultItem: {
    padding: '20px 40px',
    margin: '20px auto'
  },
  searchResultItemTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
    fontSize: '1.5em',
    marginBlockStart: '0.83em',
    marginBlockEnd: '0.83em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    textDecoration: 'none'
  }
}));

function SearchPage() {
	const connector = new AppSearchAPIConnector({
		searchKey: process.env.GATSBY_ELASTIC_SEARCH_KEY,
		engineName: process.env.GATSBY_ELASTIC_ENGINE_NAME,
		endpointBase: process.env.GATSBY_ELASTIC_ENDPOINT_BASE,
		cacheResponses: false
	});

  const classes = useStyles();

	const config = {
		apiConnector: connector,
		searchQuery: {
			disjunctiveFacets: [],
			disjunctiveFacetsAnalyticsTags: ["Ignore"],
			search_fields: {
				title: {},
				body: {},
				files: {}
			},
			result_fields: {
				title: {
					raw: {}
				},
        type: {
          raw: {}
        },
        status: {
          raw: {}
        },
        drupal_internal__nid: {
          raw: {}
        },
				body: {
					raw: {},
          snippet: {
						size: 300,
						fallback: true
          } 
				},
        files: {
          raw: {}
        }
			},
			facets: {
			}
		},
		hasA11yNotifications: true,
		a11yNotificationMessages: {
			searchResults: ({ start, end, totalResults, searchTerm }) =>
				`Searching for "${searchTerm}". Showing ${start} to ${end} results out of ${totalResults}.`
		},
		alwaysSearchOnInitialLoad: true
	};

  const SortSelect = ({onChange, options}) => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
        <Select
          id="sort-by"
          label="Sort By"
          onChange={o => onChange(o.target.value)}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

	return (
      <SearchProvider config={config}>
        <WithSearch
          mapContextToProps={({ searchTerm, setSearchTerm, results }) => ({
            searchTerm,
            setSearchTerm,
            results
          })}
        >
          {({ searchTerm, setSearchTerm, results }) => {
            console.log(results)                            
            return (
              <div className={"search-wrapper"}>
                <form noValidate autoComplete="off" className={classes.filters}>
                  <Container maxWidth="lg">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Search..."
                          variant="outlined"
                          className={classes.formControl}
                          onChange={e => setSearchTerm(e.target.value)}
                          value={searchTerm}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Sorting
                          view={SortSelect}
                          className={classes.formControl}
                          sortOptions={[
                            {
                              name: "Relevance",
                              value: "",
                              direction: ""
                            },
                            {
                              name: "Title",
                              value: "title",
                              direction: "asc"
                            }
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </form>
                <Container maxWidth="lg">
                  <List className={classes.list}>
                    {results.map(r => {
                      let identifier = null;
                      switch (r.type?.raw) {
                        case "node--article":
                        case "node--meeting":
                          identifier = r.drupal_internal__nid?.raw;
                          break;
                      }
                      let path = generateFrontendPath(r.type?.raw, r.title?.raw, identifier); 
                      let excerpt = "";
                      let lowerStr = "";
                      for (const field in r) {
                        if (field == "drupal_internal__nid") continue;
                        lowerStr = r[field].raw ? r[field].raw.toLowerCase() : ""
                        if (lowerStr.includes(searchTerm.toLowerCase())) {
                          excerpt = removeTags(unescape(r[field].raw));
                          break;
                        }
                      }
                      if (excerpt == "") excerpt = r.body ? removeTags(unescape(r.body.raw)) : excerpt;
                      return path && !r.title.raw.toLowerCase().includes("schema") && r.status.raw == "true" && r.type?.raw !== "node--knowledge_base_articles" ? (
                        <ListItem className={classes.searchResultItem} key={r.id.raw}>
													<ListItemText
														primary={
                              <Link className={classes.searchResultItemTitle} to={path}>{r.title?.raw}</Link>
                            }
														secondary={ excerpt && excerpt.substring ? excerpt.substring(0, 299) + '...' : ''}														
													/>
                        </ListItem>
                      ) : <></>
                    })}
                  </List>
                  <Paging />
                  <PagingInfo />
                </Container>
              </div>
            );
                
          }}
        </WithSearch>
      </SearchProvider>
	);
}

export default SearchPage;

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "search" } } }) {
      nodes {
        frontmatter {
          title
        }
      }
    }
  }
`
